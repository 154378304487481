import React from "react"
import Header from "./layout/Header"
import Footer from "./layout/Footer"
// import 'bootstrap/dist/css/bootstrap.min.css'
import "../style/style.scss"
import "../fonts/icomoon/style.css"
import {StaticQuery, graphql} from "gatsby";

export default class Layout extends React.Component {

    render() {
        return (
            <StaticQuery
                query={graphql`
                    query LayoutQuery {
                        allDatoCmsProduct {
                            edges {
                                node {
                                    title
                                }
                            }
                        }
                        allDatoCmsAkcesor {
                            edges {
                                node {
                                    title
                                }
                            }
                        }
                        allDatoCmsSerwi {
                            edges {
                                node {
                                    title
                                }
                            }
                        }
                        allDatoCmsAutomatyka {
                            edges {
                                node {
                                    title
                                }
                            }
                        }
                    }
                `}
                render={data => (
                    <div className={this.props.className}>
                        <Header></Header>
                        {this.props.children}
                        <Footer></Footer>
                    </div>
                )}
            />
        )
    }
}