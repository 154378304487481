import React from "react"
import {StaticQuery, Link, graphql} from "gatsby"

export default class Footer  extends React.Component {
    render() {
        return(
            <StaticQuery
                query={graphql`
                    query FooterQuery {
                        allDatoCmsUsage {
                            edges {
                                node {
                                    title
                                    slug
                                }
                            }
                        }
                        allDatoCmsCategory {
                            edges {
                                node {
                                    title
                                    slug
                                }
                            }
                        }
                    }
                `}
                render={data => (
                    <footer className="footer">
                        <div className="footer__content container">
                            <div className="item">

                                <h4>Produkty</h4>
                                <ul className="arrow-list">
                                    {data.allDatoCmsCategory.edges.map(({ node: item }) => (
                                        <li key={item.title}><a href={`/category/${item.slug}`} title="">{item.title}</a></li>
                                    ))}
                                </ul>
                            </div>
                            <div className="item">
                                <h4>Zastosowanie</h4>
                                <ul className="arrow-list">
                                    {data.allDatoCmsUsage.edges.map(({ node: item }) => (
                                        <li key={item.title}><a href={`/usage/${item.slug}`} title="">{item.title}</a></li>
                                    ))}
                                </ul>
                            </div>
                            <div className="item">
                                <h4>Informacje</h4>
                                <ul className="arrow-list">
                                    <li><Link to="/about/" title="O Nas">O Nas</Link></li>
                                    <li><Link to="/service/" title="Serwis">Serwis</Link></li>
                                    <li><Link to="/contact/" title="Kontakt">Kontakt</Link></li>
                                </ul>
                            </div>
                            <div className="item">
                                <h4>Kontakt</h4>
                                <p>Tel. <a href="tel:507266234">507-266-234</a><br/>
                                    <a href="mailto:kontakt@falve.pl">kontakt@falve.pl</a><br/>
                                    <a href="mailto:serwis@falve.pl">serwis@falve.pl</a>
                                </p>
                            </div>
                        </div>
                    </footer>
                )}
            />
        )
    }
}