import React from "react"
import * as cn from "classnames"
import {graphql, Link, StaticQuery} from "gatsby"
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import logo from "../../../static/falve-logo.png"

export default class Header extends React.Component {
    state = {
        showMenu: false,
        showMobileMeu: false
    }

    constructor(props) {
        super(props);
        this.productRef = React.createRef();
    }

    renderUsage(data) {
        return (
            <div className="navigation navigation--sm">
                {data.allDatoCmsUsage.edges.map(({ node: item }) => (
                    <div className={cn(`item usage--${item.idId}`)} key={item.idId}>
                        <a href={`/usage/${item.slug}`} className="title">
                            <span className={cn("icon", `icon-${item.className}`)}></span>
                            <span>{item.title}</span>
                        </a>
                        <ul className="arrow-list">
                            {data.allDatoCmsSubUsage.edges.filter(({node: subitem}) => subitem.mainUsage.title === item.title).map(({node: subitem}, idx) =>(
                                <li key={idx}><a href={`/usage/${item.slug}/${subitem.slug}`}>{subitem.title}</a></li>
                            ))}
                        </ul>
                    </div>
                ))}
                {/*<div className="item">*/}
                {/*    <div className="preview">*/}
                {/*        <span>POMPY ZATAPIALNEZ wirnikiem vortex</span>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        )
    }

    renderCategory(data) {
        return (
            <div className="navigation">
                {data.allDatoCmsCategory.edges.map(({ node: item }) => (
                    <div className={cn(`item category--${item.idId}`)} key={item.idId}>
                        <a href={`/category/${item.slug}`} className="title">
                            <span>{item.title}</span>
                        </a>
                        <ul className="arrow-list">
                            {data.allDatoCmsSubCategory.edges.filter(({node: subitem}) => subitem.mainCategory.title === item.title).map(({node: subitem}, idx) =>(
                                <li key={idx}><a href={`/category/${item.slug}/${subitem.slug}`}>{subitem.title}</a></li>
                            ))}
                        </ul>
                    </div>
                ))}
                {/*<div className="item">*/}
                {/*    <div className="preview">*/}
                {/*        <span>POMPY ZATAPIALNEZ wirnikiem vortex</span>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        )
    }

    render() {
        const s = this.state

        return (
            <StaticQuery
                query={graphql`
                    query HeaderQuery {
                        allDatoCmsUsage(
                            sort: {
                              fields: [idId]
                              order: ASC
                            }
                        ) {
                            edges {
                                node {
                                    idId
                                    title
                                    slug
                                    className
                                }
                            }
                        }
                        allDatoCmsSubUsage {
                            edges {
                                node {
                                    title
                                    slug
                                    mainUsage {
                                        title
                                        slug
                                    }
                                }
                            }
                        }
                        allDatoCmsCategory(
                            sort: {
                              fields: [idId]
                              order: ASC
                            }
                        ) {
                            edges {
                                node {
                                    idId
                                    title
                                    slug
                                }
                            }
                        }
                        allDatoCmsSubCategory {
                            edges {
                                node {
                                    title
                                    slug
                                     mainCategory {
                                        title
                                        slug
                                    }
                                }
                            }
                        }
                    }
                `}
                render={data => (
                    <header className="header">
                        <div className="header__content container">
                            <div className="header__top">
                                <a href="/" className="logo">
                                    <img src={logo} alt="Falve" />
                                </a>
                                <span className={cn("menu-mobile", s.showMobileMeu && "open")} onClick={() => this.setState({showMobileMeu: !s.showMobileMeu})}>
                                    <span></span>
                                </span>
                            </div>
                            <div className={cn("menu", s.showMobileMeu && "open")} ref={this.productRef}>
                                <nav>
                                    <ul>
                                        <li className="more">
                                            <OverlayTrigger trigger="click" rootClose overlay={this.renderCategory(data)} placement="bottom"><span>Produkty</span></OverlayTrigger>
                                        </li>
                                        <li className="more">
                                            <OverlayTrigger trigger="click" rootClose overlay={this.renderUsage(data)} placement="bottom"><span>Zastosowanie</span></OverlayTrigger>
                                        </li>
                                        <li><Link to="service/" onClick={() => this.setState({showMobileMeu: false})} activeClassName="active">Serwis</Link></li>
                                        <li><Link to="about/" onClick={() => this.setState({showMobileMeu: false})} activeClassName="active">O Firmie</Link></li>
                                        <li><Link to="contact/" onClick={() => this.setState({showMobileMeu: false})} activeClassName="active">Kontakt</Link></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </header>
                )}
            />

        )
    }
}